import { currency } from '@/utils/formatters';

// Product promo ribbon
export const PROMO_RIBBON_TYPE = {
  SALE_AMOUNT_RIBBON: 'SALE_AMOUNT_RIBBON',
  DISCOUNT_NAME_RIBBON: 'DISCOUNT_NAME_RIBBON',
  DIGITAL_COUPON_RIBBON: 'DIGITAL_COUPON_RIBBON',
  COUPON_NOT_AVAILABLE_RIBBON: 'COUPON_NOT_AVAILABLE_RIBBON',
};

// Promo Ribbon Variant
export const PROMO_RIBBON_VARIANT = {
  DANGER: 'danger',
  SECONDARY: 'secondary',
  INFO: 'info',
};

/**
 * Product Promotional Ribbon Configuration by Ribbon Type
 * Ribbon Types - SALE_AMOUNT_RIBBON, DISCOUNT_NAME_RIBBON
 *
 * variant - Ribbon Variant
 * canDisplay - Condition to check if the promo ribbon can be displayed
 * getTextContent - Method to get the text content for the promotional ribbon
 */
export const RIBBON_CONFIG = {
  // Configuration for sale amount product promotional ribbon
  [PROMO_RIBBON_TYPE.SALE_AMOUNT_RIBBON]: {
    variant: PROMO_RIBBON_VARIANT.INFO,

    /**
     * Method to check if the product has any discount
     * @param {Object} product - Product Information
     * @returns {Boolean} - true | false - whether to show promo ribbon
     */
    canDisplay(product) {
      if (!product && !product.suggestedPrice && !product.act) {
        return false;
      }
      const { suggestedPrice, actualPrice, actualPriceDivider } = product;
      return actualPrice / actualPriceDivider < suggestedPrice;
    },

    /**
     * Method to return the text content for the sale amount promotional ribbon
     * @param {Object} product - Product Information
     * @returns {String} ribbon text content
     */
    getTextContent(product) {
      const { suggestedPrice, actualPrice, actualPriceDivider } = product;
      const savings = suggestedPrice - actualPrice / actualPriceDivider;
      const unit = product.weightProfile ? product.weightProfile.abbrv : 'ea';
      return `Save ${currency(savings)} / ${unit}`;
    },
  },

  // Configuration for discount name product promotional ribbon
  [PROMO_RIBBON_TYPE.DISCOUNT_NAME_RIBBON]: {
    variant: PROMO_RIBBON_VARIANT.SECONDARY,

    /**
     * Method to check if the product has discount name
     * @param {Object} product - Product Information
     * @returns {Boolean} - true | false - whether to show promo ribbon
     */
    canDisplay(product) {
      return product.autoDiscount;
    },

    /**
     * Method to return the text content for the sale/discount name promotional ribbon
     * @param {Object} product - Product Information
     * @returns {String} - ribbon text content
     */
    getTextContent(product) {
      return product.autoDiscount;
    },
  },

  [PROMO_RIBBON_TYPE.DIGITAL_COUPON_RIBBON]: {
    variant: PROMO_RIBBON_VARIANT.SECONDARY,

    canDisplay(offer) {
      if (offer?.redeemLimit) {
        return parseInt(offer.redeemLimit, 10) > 1;
      }
      return false;
    },

    getAvaliableTextContext(redeemLimit) {
      return `Use ${redeemLimit}x`;
    },
    getClippedTextContext(usagesLeft) {
      if (usagesLeft === 1) {
        return `${usagesLeft} Use Left`;
      }
      return `${usagesLeft} Uses Left`;
    },
    getRedeemedTextContext(usagesRedeemed) {
      return `Used ${usagesRedeemed}x`;
    },
  },

  [PROMO_RIBBON_TYPE.COUPON_NOT_AVAILABLE_RIBBON]: {
    variant: PROMO_RIBBON_VARIANT.DANGER,

    canDisplay(offer) {
      return !offer?.validForStore;
    },
  },
};
