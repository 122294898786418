import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import ExternalCoupon from '@/models/entities/items/external-coupon';

export default defineComponent({
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      clippedCoupons: 'coupons/getClippedCoupons',
      redeemedCoupons: 'coupons/getRedeemedCoupons',
    }),
  },
  methods: {
    displayableCoupons(item): Array<ExternalCoupon> {
      if (item?.coupons) {
        if (this.isCustomer) {
          return item.coupons?.filter(i => this.canDisplayCoupon(i));
        }
        return item.coupons?.filter(i => this.couponDisplayable(i));
      }
      return [];
    },
    canDisplayCoupon(coupon): boolean {
      return (
        this.clippedCoupons?.includes(coupon.id) ||
        this.redeemedCoupons?.includes(coupon.id) ||
        this.couponDisplayable(coupon)
      );
    },
    couponDisplayable(coupon): boolean {
      return coupon?.validForStore;
    },
  },
});
