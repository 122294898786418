<template>
  <platform-renderer>
    <template v-slot:onDesktop>
      <div class="wc-coupon-card-base card wc-card shadow-sm">
        <div class="flex-column wc-coupon-card__top border-secondary">
          <div class="wc-coupon-card__top-title primary font-weight-bold p-1">
            {{ couponTitle }}
          </div>
          <router-link
            :aria-label="`Brand ${coupon.brand}`"
            :to="`/b?g=BRD-${coupon.brand}`"
            class="link-dark underline-link-from-center font-size-xs"
          >
            {{ coupon.brand }}
          </router-link>
        </div>
        <div class="wc-coupon-image-wrapper border-secondary">
          <div :aria-label="`${coupon.name} for ${coupon.brand} modal`" class="d-block w-100 h-100">
            <WCImage
              :imageSrc="coupon.imageUrl || ''"
              :imageAlt="coupon.name"
              imageClass="wc-header-image"
              defaultClass="w-100 h-100 p-5"
              :defaultTitle="coupon.name"
            />
          </div>
          <div class="wc-coupon-ribbon-container">
            <div class="mr-1">
              <WCDigitalCouponRibbon
                :offer="coupon"
                :couponFilter="couponFilter"
                :isClipped="evaluateClipStatus"
                :isRedeemed="evaluateRedeemedStatus"
              />
            </div>
            <div>
              <WCCouponNotAvailableRibbon
                :offer="coupon"
                class="mr-1"
                data-testid="coupon-not-available-ribbon"
              />
            </div>
          </div>
        </div>
        <div class="card-body d-flex flex-column wc-card-body">
          <div class="flex-column">
            <div
              class="wc-coupon-card__bottom-description mr-1 link-dark underline-link-from-center"
              :aria-label="`${coupon.name} for ${coupon.brand} modal`"
              :id="coupon.id"
              @click="openMoreInfoModal(couponFilter)"
            >
              {{ coupon.name }}
            </div>
            <div class="wc-coupon-card__bottom-content">
              <div class="wc-coupon-card__date">
                {{ $t('expires') }}: {{ $filters.formatDate(coupon.endDate) }}
              </div>
              <div
                class="wc-coupon-card__more-info underline-link-from-center font-size-xs link-primary"
                @click.stop="openMoreInfoModal(couponFilter)"
                ref="modalTriggerButton"
              >
                {{ $t('moreInfo') }}
              </div>
            </div>
            <WCCouponClipButton
              class="wc-coupon-card__bottom-button"
              :couponId="coupon.id"
              :availableLabel="$t('clipCoupon')"
              :clippedLabel="$t('clippedCoupon')"
              :redeemedLabel="$t('couponRedeemed')"
              :couponFilter="couponFilter"
              :disabled="false"
              :isClipped="evaluateClipStatus"
              :isRedeemed="evaluateRedeemedStatus"
              :handleClip="handleClip"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:onMobile>
      <div class="wc-coupon-card-mobile__wrapper">
        <div class="wc-coupon-card-mobile shadow-sm rounded overflow-auto">
          <div class="wc-coupon-card-mobile__top d-flex">
            <div class="wc-coupon-card-mobile__title font-weight-bold ml-2">
              {{ couponTitle }}
            </div>
            <template v-if="!evaluateClipStatus && $configuration.allowCouponClipping">
              <button
                @click="handleClip"
                :aria-label="$t('clipCoupon')"
                class="unstyled-btn wc-coupon-card-mobile__coupon-icon mr-2 mt-2"
              >
                <font-awesome-icon icon="scissors" size="lg" />
              </button>
            </template>
            <template v-else-if="evaluateClipStatus && $configuration.allowCouponClipping">
              <button
                @click="handleClip"
                :aria-label="$t('clippedCoupon')"
                class="unstyled-btn wc-coupon-card-mobile__coupon-icon mr-2 mt-2"
              >
                <font-awesome-icon icon="circle-check" size="lg" />
              </button>
            </template>
          </div>
          <div class="wc-coupon-card-mobile__body d-flex p-2">
            <div
              class="d-flex flex-column align-items-center justify-content-center h-100 w-100"
              @click="openMoreInfoModal(couponFilter)"
            >
              <WCImage
                :imageSrc="coupon.imageUrl || ''"
                :imageAlt="coupon.name"
                imageClass="wc-coupon-card-mobile__img"
                defaultClass="wc-coupon-card-mobile__camera-icon"
                :defaultTitle="coupon.name"
              />
              <div class="wc-coupon-ribbon-wrap-mobile">
                <WCDigitalCouponRibbon
                  :offer="coupon"
                  :couponFilter="couponFilter"
                  :isClipped="evaluateClipStatus"
                  :isRedeemed="evaluateRedeemedStatus"
                />
              </div>
            </div>
          </div>
          <div class="wc-coupon-card-mobile__bottom d-flex font-size-xs">
            <div
              class="wc-coupon-card-mobile__desc align-items-center flex-grow-1 mx-1 text-break underline-link-from-center font-weight-bold font-size-sm"
              :class="{ 'mb-2': couponDisplayable(coupon) }"
              :aria-label="`${coupon.name} for ${coupon.brand} modal`"
              @click="openMoreInfoModal(couponFilter)"
            >
              {{ coupon.name }}
            </div>

            <div
              v-if="!couponDisplayable(coupon)"
              class="wc-coupon-card-mobile__desc text-danger mb-2 text-break font-weight-bold"
              data-testid="coupon-not-available-text"
            >
              <font-awesome-icon class="mr-1 text-danger" icon="circle-exclamation" />
              <span>{{ $t('notAvailableAtThisStore') }}</span>
            </div>

            <div class="wc-coupon-card-mobile__footer">
              <div class="wc-coupon-card-mobile__date text-truncate flex-grow-1 ml-1 mb-1">
                {{ $t('expires') }}: {{ $filters.formatDate(coupon.endDate) }}
              </div>
              <a
                class="wc-coupon-card-mobile__more-info link-primary underline-link-from-center mr-1 mb-1"
                @click="openMoreInfoModal(couponFilter)"
              >
                {{ $t('moreInfo') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </platform-renderer>
</template>
<script>
import WCImage from '@/components/WCImage/WCImage.vue';
import WCCouponClipButton from '@/components/WCCouponClipButton/WCCouponClipButton.vue';
import CouponMixin from '@/modules/coupons/mixins/CouponMixin';
import CouponAvailabilityMixin from '@/modules/coupons/mixins/CouponAvailabilityMixin';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import { defineComponent } from 'vue';
import WCDigitalCouponRibbon from '@/components/WCDigitalCouponRibbon/WCDigitalCouponRibbon.vue';
import WCCouponNotAvailableRibbon from '@/components/WCCouponNotAvailableRibbon/WCCouponNotAvailableRibbon.vue';

export default defineComponent({
  name: 'WCCouponCard',
  components: {
    WCImage,
    WCCouponClipButton,
    WCDigitalCouponRibbon,
    WCCouponNotAvailableRibbon,
    PlatformRenderer,
  },
  mixins: [CouponMixin, CouponAvailabilityMixin],
  props: {
    couponFilter: {
      type: String,
    },
  },
});
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-card-base {
  display: flex;
}

.wc-coupon-image-wrapper {
  position: relative;

  display: inline-block;
  height: 15rem;
  padding: $pad-3 * 1.25;
  max-width: 100%;
  border-bottom: 1px solid #ddd;
}

.wc-card {
  border-radius: 5px;
  width: 100%;
}

.wc-header-image {
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;

  object-fit: scale-down;
  max-height: calc(100% - 2.5rem);
  max-width: 100%;
  margin: auto;
  padding: $pad-2 * 1.25;
}

.wc-coupon-card__top {
  display: flex;
  align-items: center;

  min-height: 13%;
  padding-bottom: $pad-1;
  border-bottom: 1px solid #ddd;
}

.wc-coupon-card__top-title {
  color: var(--primary, $primary);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.wc-coupon-ribbon-container {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.wc-coupon-ribbon-wrap-mobile {
  position: absolute;
  bottom: 4.7rem;
  right: 0.5rem;
}

.wc-coupon-card__bottom-description {
  display: block;
  cursor: pointer;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.wc-coupon-card__bottom-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: $margin-1;
}
.wc-coupon-card__bottom-button {
  max-width: 100%;
}
.wc-coupon-card__bottom {
  display: flex;
  max-width: 100%;
}
.wc-coupon-card__date {
  font-size: $font-size-xs;
  align-self: center;
}
.wc-coupon-card__more-info {
  cursor: pointer;
}

.wc-coupon-card-mobile__wrapper {
  background: var(--white, $white);
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 15rem;

  .VueCarousel-slide {
    max-width: 96%;
  }
  &.wc-card__wrapper--fixed-size {
    width: 10.6rem;
  }
}

.wc-coupon-card-mobile {
  border: none;
  position: relative;
  width: inherit;
  height: inherit;
  line-height: normal;

  .wc-coupon-card-mobile__top {
    height: 15%;
    border-bottom: 1px solid var(--secondary, $secondary);
    position: relative;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    .wc-coupon-card-mobile__title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: var(--primary, $primary);
    }
    .wc-coupon-card-mobile__coupon-icon {
      color: var(--primary, $primary);
      cursor: pointer;
    }
  }

  .wc-coupon-card-mobile__body {
    height: 55%;
    cursor: pointer;
    border-bottom: 1px solid var(--secondary, $secondary);
    a {
      color: var(--dark, $dark);
    }

    .wc-coupon-card-mobile__camera-icon {
      font-size: $font-size-7xl;
    }
    .wc-coupon-card-mobile__img {
      object-fit: contain;
      max-width: calc(100% - 1rem);
      max-height: calc(100% - 1rem);
      width: auto;
      height: auto;
    }
  }

  .wc-coupon-card-mobile__bottom {
    height: 30%;
    flex-direction: column;

    .wc-coupon-card-mobile__desc {
      padding-top: $pad-1;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .wc-coupon-card-mobile__footer {
    display: flex;
    flex-direction: row;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}
.wc-coupon-card-mobile__more-info {
  color: var(--primary, $primary);
  cursor: pointer;
}

@include media-breakpoint-only(xs) {
  .wc-coupon-card-mobile__wrapper {
    justify-content: center;

    &.wc-card__wrapper--fixed-size {
      width: 94%;
    }
  }
}
</style>
