
import { defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { PROMO_RIBBON_TYPE, RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';

export default defineComponent({
  components: { FontAwesomeIcon },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ribbonConfig() {
      return RIBBON_CONFIG[PROMO_RIBBON_TYPE.COUPON_NOT_AVAILABLE_RIBBON];
    },
    isRibbonVisible() {
      return this.ribbonConfig.canDisplay(this.offer);
    },
    textContent() {
      return this.$t('notAvailableAtThisStore');
    },
    variant() {
      return this.ribbonConfig.variant;
    },
  },
});
