import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.isRibbonVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["wc-coupon-ribbon py-1 px-2 font-size-xs rounded", _ctx.variant]),
        "data-testid": "coupon-not-available-ribbon"
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: "mr-1 text-black",
          icon: "circle-exclamation"
        }),
        _createTextVNode(),
        _createElementVNode("span", null, _toDisplayString(_ctx.textContent), 1)
      ], 2))
    : _createCommentVNode("", true)
}